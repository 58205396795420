import About from "./components/about";
import Exams from "./components/exams";
import Fascilities from "./components/fascilities";
import Hero from "./components/hero";
import Social from "./components/social";

export default function Home() {
    return (
        <div>
            <Hero />
            <div className="max-w-[100rem] mx-auto px-5 md:px-0">
                <About />
                <Fascilities />
                <Exams />
            </div>
        </div>
    )
} 