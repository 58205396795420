import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Button(props) {
    return (
        props.light?
        <div className={`rounded-full bg-gradient ${props.className}`} onClick={props.onClick ?? null}>
            <Link
            to={props.to ?? '/apply'} >
                <div className="bg-white m-[3px]  text-black flex gap-3 rounded-full px-3 py-2 items-center hover:bg-black hover:m-[3px] hover:text-b transition-all duration-300 ease-in-out">
                    <FaArrowRight scale={0.5} />
                    <span className={`text-sm`}>{props.text ?? "Apply"}</span>
                </div>
            </Link>
        </div>
        :
        <div className={`rounded-full bg-gradient w-max ${props.className}`} onClick={props.onClick ?? null}>
            <Link
            to={props.to ?? '/apply'} >
                <div className="bg-black m-[3px] rounded-full text-white flex gap-3 px-3 py-2 items-center hover:text-b hover:m-[3px] hover:bg-black/25 transition-all duration-300 ease-in-out">
                    <FaArrowRight scale={0.5} />
                    <span className={`text-sm`}>{props.text ?? "Apply"}</span>
                </div>
            </Link>
        </div>
    )
}