import React from 'react';
import Card from './card';

function Fascilities() {
  const cardsData = [
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.11.27%402x.webp?alt=media&token=421b22eb-20fb-4efe-b694-04d5d4dad06d", adjustment:"bg-cover", title: "VR Room", subtitle: "Experience the future", facility: "vr-room" },
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.12.03%402x.webp?alt=media&token=62d967aa-3d89-4647-ba76-e9a1d34cf48d", adjustment:"bg-cover", title: "Creative Arts", subtitle: "Express yourself", facility: "creative-arts" },
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.10.42%402x.webp?alt=media&token=b94739c4-d4f7-407c-b359-5826369029d6", adjustment:"bg-cover bg-center bg-top", title: "Digital Creativity", subtitle: "Create media", facility: "digital-creativity" },
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.15.42%402x.webp?alt=media&token=fd158da2-dc9c-4e30-8a36-f63c2fd67290", adjustment:"bg-cover", title: "Adulting 101", subtitle: "Life skills", facility: "adulting-101" },
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.11.38%402x.webp?alt=media&token=19152ca0-f9f5-4ace-869c-7b50ce722150", adjustment:"bg-cover bg-bottom", title: "Socialisation", subtitle: "Connect positively", facility: "socialisation" },
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.10.54%402x.webp?alt=media&token=10fcf508-c13a-4ecf-be1c-6724b3f0515f", adjustment:"bg-cover", title: "Activities", subtitle: "Engage & compete", facility: "activities" },
    { img: "https://firebasestorage.googleapis.com/v0/b/inventum-98847.appspot.com/o/website%2Fcambridgeexams.webp?alt=media&token=3cd7d742-c722-418d-aa8c-60f585e7f0b2", adjustment:"bg-cover bg-right", title: "Examination Centre", subtitle: "Cambridge & Pearson", facility: "examination-centre" },
    { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.11.10%402x.webp?alt=media&token=28675e57-1fe4-4aa2-a83e-fa1d473a1fd8", adjustment:"bg-cover", title: "Trips & Summer Camps", subtitle: "Travel The World", facility: "trips-summer-camps" },
  ];

  return (
    <section className='w-full'>
      <h2 className='text-center'>Facilities</h2>
      <div className="flex flex-wrap gap-2 justify-center max-w-5xl mx-auto lg:p-2 px-10 ">
        {cardsData.map((card, index) => (
          <Card adjustment={card.adjustment} key={index} img={card.img} title={card.title} subtitle={card.subtitle} facility={card.facility ?? 'not-found'} />
        ))}
      </div>
    </section>
    
  );
}

export default Fascilities;
