import Button from "../../../components/Button";
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { ChevronDown } from "react-feather";

const AccordianContext = createContext()

function Accordian({ children, value, onChange, ...props }) {
    const [selected, setSelected] = useState(value);

    useEffect(() => {
        onChange?.(selected)
    }, [selected])

    return (
        <ul {...props}>
            <AccordianContext.Provider value={{ selected, setSelected }}>
                {children}
            </AccordianContext.Provider>
        </ul>
    )
}


function AccordianItem({ children, value, index, trigger,selectedData, ...props }) {
    const { selected, setSelected } = useContext(AccordianContext);

    const open = selected === value;
    const ref = useRef(null)    

    return (
        <li className="bg-white" {...props}>
            <div 
            className="flex justify-between items-center py-2.5"
            role="button"
            onClick={() => setSelected(open ? null : value)}
            >
                <div className="flex items-center space-x-3">
                    <p className="text-xl md:text-2xl font-light text-start">{trigger}</p>
                </div>
                <ChevronDown size={16} className={`transition-transform ${open ? "rotate-180" : ""}`} />
            </div>
            <div className="overflow-y-hidden transition-all duration-300 ease-in-out"
                style={{ height: open ? ref.current?.offsetHeight || 0 : 0 }}
            >
                <div className="pt-2 p-4" ref={ref}>
                    {children}
                </div>
            </div>
        </li>
    )
}

const data = [
    {
    title: "Are you building a professional career in sports, such as rugby?",
    text: "Balancing a demanding sports career with your studies can be tough. Our programmes offer the flexibility you need to focus on your training while gaining a prestigious education. Whether you're on the field or travelling for games, you can learn on your own schedule and still work towards the internationally recognised Cambridge qualifications."
    },
    {
    title: "Do you want to study internationally or overseas?",
    text: "Gain a competitive edge with Cambridge qualifications, renowned worldwide as a prestigious educational passport. Our programmes allow you to study the same Cambridge curriculum, designed to open doors to universities and careers globally, all from the comfort of your home."
    },
    {
    title: "Is traditional school too boring for you?",
    text: "If you're tired of the traditional classroom setting, we have a more dynamic alternative. Our curriculum integrates emerging technologies, practical mindset modules, and provides the flexibility to learn at your own pace. Take control of your learning journey and make it engaging."
    },
    {
    title: "Do you enjoy studying remote on your own time schedule?",
    text: "Our programmes are tailored for students who thrive in a flexible environment. Study when it suits you, manage your time effectively, and never miss out on life’s important moments. Remote learning gives you control over your education, without the rigid structure of a traditional school."
    },
    {
    title: "Do you want to study the prestigious Cambridge curriculum at fair prices?",
    text: "We believe quality education shouldn’t come with an exorbitant price tag. Our courses are based on the prestigious Cambridge curriculum, renowned worldwide for its rigorous standards and recognition by top universities, but offered at accessible prices, ensuring you receive an excellent education without breaking the bank."
    }
];

export default function About() {
    return (
        <section className="text-center py-10 w-full">
            <div className="lg:flex block">
                <div className="px-10 lg:w-1/2 lg:text-start">
                    <h2 className="">What is iHubs</h2>
                    <p className="pb-5 prose">iHubs is an innovative model that bridges the gap between online schooling and socialisation. By partnering with Inventum International Online School, iHubs offers a new hybrid approach to education, seamlessly blending the benefits of traditional and online learning. Students attending Inventum International Online School now have the opportunity to enhance and develop their social and career skills while exploring their creativity. This platform empowers students to not only excel academically but also grow personally and professionally in a supportive, dynamic environment.</p>
                    <Button text="Apply" className="mx-auto lg:mx-0" />
                </div>
                <div className="lg:w-1/2 p-10">
                    <Accordian className="space-y-3">
                        {data.map((item, index) => (
                            <AccordianItem trigger={item.title} selectedData={data} index={index+1} className="border-t border-b-0 border-b pt-4 text-start" value={`${index+1}`}>
                                {item.text}
                            </AccordianItem>
                        ))}
                    </Accordian>
                </div>
            </div>
            <div className="px-0">
                <div className="flex flex-col md:flex-row gap-10 justify-center py-20">
                    <img className="rounded-xl h-[500px] md:h-auto md:w-2/5 object-cover" src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.26.04%402x.webp?alt=media&token=f587ae6f-9917-4c2c-a3de-d31a7ec212f5" alt=""></img>
                    <div className="prose prose-h3:text-2xl md:prose-h3:text-4xl prose-h3:font-bold text-start md:w-1/2 my-auto">
                        <h3>Online Schooling at Inventum through iHubs</h3>
                        <ul className="">
                            <li>Prestigious Cambridge Qualification: Earn a globally respected Cambridge qualification recognised by top universities and employers.</li>
                            <li>Future Technology and Mindset Modules: Study innovative subjects on future technologies and growth mindsets, preparing you for modern challenges.</li>
                            <li>Flexible Schedule: Learn at your own pace with adaptable online schedules that fit your lifestyle.</li>
                            <li>Cambridge Advantage: Benefit from a rigorous and comprehensive Cambridge curriculum, superior to IEB and GAPS.</li>
                            <li>Study Abroad at Ease: A-Levels facilitate smoother admissions to international universities.</li>
                            <li>Individualised Support: Receive personalised attention tailored to your unique learning needs.</li>
                        </ul>
                        <Button />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row-reverse gap-10 justify-center py-20">
                    <img className="rounded-xl h-[500px] md:h-auto md:w-2/5 object-cover" src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/oluwatobi-fasipe-gu9X51yzei0-unsplash.webp?alt=media&token=73f37e43-8119-4e9d-9d79-876c7498de64" alt=""></img>
                    <div className="prose prose-h3:text-2xl md:prose-h3:text-4xl prose-h3:font-bold text-start md:w-1/2 my-auto">
                        <h3>The benefits of iHubs</h3>
                        <ul className="">
                            <li>Hybrid Learning Model: iHubs provides the socialisation aspect to online schooling, combining the benefits of traditional and online learning for a holistic educational experience.</li>
                            <li>Schooling without negative socialisation: iHubs mitigates the negative aspects of socialisation that traditional brick-and-mortar schools have.</li>
                            <li>Innovative Facilities: Access VR and AR rooms, digital recording studios, and art lessons to enhance creativity and make learning engaging and fun.</li>
                            <li>Life Skills Education: "Adulting 101" classes teach essential life skills, preparing students for independent living and future challenges.</li>
                            <li>Diverse Activities: Participate in exciting trips, sports days, hiking, and social events that promote teamwork, leadership, and social skills.</li>
                            <li>Exam Support: iHubs serves as an associate exam centre for Cambridge and Pearson exams, offering a familiar and supportive environment for students to excel academically.</li>
                        </ul>
                        <Button />
                    </div>
                </div>
            </div>
        </section>
    )
}