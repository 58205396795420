import React from 'react';

const Schedule = ({ activities }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  return (
    <div className="text-center container mx-auto p-6">
      <h2 className="">Weekly Schedule</h2>
      <p className='prose mx-auto'>iHubs members select four sessions they wish to attend each week. We encourage members to select as broad interests as they can.</p>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4">
        {/* Time Slots for Each Day */}
        {days.map((day) => (
          <div key={day} className="flex flex-col space-y-4">
            <div key={day} className="text-center text-xl font-semibold text-gray-600">
            {day}
          </div>
            {activities.map((activity, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg shadow-lg text-center ${
                  activity[day] ? 'bg-gradient-to-r from-b/70 to-b text-white' : 'bg-gray-100 text-black'
                }`}
              >
                <div className="text-sm font-medium">{activity.time}</div>
                <div className="text-lg font-bold">{activity[day] || 'No Activity'}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedule;
