import Button from "../../../components/Button";

export default function Offer() {
    return (
        <div className="relative mt-14">
            <div className="flex flex-col md:flex-row gap-10 justify-center py-20 ">
                <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                    <img
                        className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
                        src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.26.04%402x.webp?alt=media&token=f587ae6f-9917-4c2c-a3de-d31a7ec212f5"
                        alt=""
                        />
                       <svg
              className="absolute inset-y-0 right-0 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block -scale-100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

                </div>
               <div className="w-[45%]">
               </div>
                <div className="prose prose-h3:text-2xl md:prose-h3:text-4xl prose-h3:font-bold text-start md:w-1/2 my-auto z-10">
                    <h3>Online Schooling at Inventum through iHubs</h3>
                    <ul className="">
                        <li>Prestigious Cambridge Qualification: Earn a globally respected Cambridge qualification recognised by top universities and employers.</li>
                        <li>Future Technology and Mindset Modules: Study innovative subjects on future technologies and growth mindsets, preparing you for modern challenges.</li>
                        <li>Flexible Schedule: Learn at your own pace with adaptable online schedules that fit your lifestyle.</li>
                        <li>Cambridge Advantage: Benefit from a rigorous and comprehensive Cambridge curriculum, superior to IEB and GAPS.</li>
                        <li>Study Abroad at Ease: A-Levels facilitate smoother admissions to international universities.</li>
                        <li>Individualised Support: Receive personalised attention tailored to your unique learning needs.</li>
                    </ul>
                    <Button />
                </div>
            </div>
        </div>
    )
}