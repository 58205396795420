import React, { useState } from 'react';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

export default function Newsletter(props) {

  return (
    <div className="bg-white py-10">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7 m-auto">
          {props.general ? (
            <h2 className="inline sm:block lg:inline xl:block text-black">
              Get notified when a new <span className="text-b">iHubs</span> opens.
            </h2>
          ) : (
            <h2 className="inline sm:block lg:inline xl:block text-black">
              Get notified when <span className="text-b">iHubs {props.location}</span> goes live.
            </h2>
          )}
          <p className="block">Sign up for our newsletter.</p>
        </div>
        <div className="w-full h-[17rem] lg:col-span-5 my-auto">
        <iframe
          src="https://api.leadconnectorhq.com/widget/form/uSyvMvLCQTXzN7QPSTck"
          id="inline-uSyvMvLCQTXzN7QPSTck" 
          data-layout="{'id':'INLINE'}"
          data-trigger-type="alwaysShow"
          data-trigger-value=""
          data-activation-type="alwaysActivated"
          data-activation-value=""
          data-deactivation-type="neverDeactivate"
          data-deactivation-value=""
          data-form-name="iHubs Newsletter"
          data-layout-iframe-id="inline-uSyvMvLCQTXzN7QPSTck"
          data-form-id="uSyvMvLCQTXzN7QPSTck"
          title="iHubs Newsletter"
          style={{width:"100%", height:"100%",border:"none"}}
              >
        </iframe>
        <script src="https://link.msgsndr.com/js/form_embed.js"></script>
        </div>
      </div>
    </div>
  );
}
