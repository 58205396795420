import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { PopupButton } from "react-calendly";

export default function Hero() {
    return (
    <div className="relative lg:h-screen">
        <div className="mx-auto max-w-7xl my-auto px-1 lg:px-0">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 flex items-center justify-center lg:pr-0 lg:my-auto lg:h-screen py-24">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                iHubs supports <span className="text-b italic">online/homeschool students</span> through social and academic development
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                While online education and homeschooling offers unparalleled flexibility and allows learners to focus on their studies, it often lacks the social interaction crucial for personal development. iHubs provides the perfect solution to this challenge by offering centres located all over the world, where students can engage in their social development in a safe and secure environment.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Button
                    text="Get started"
                  />
                  <Link to="#" className="text-sm font-semibold leading-6 text-gray-900">
                    <PopupButton
                            url="https://calendly.com/c_steyn/30min"
                            rootElement={document.getElementById("root")}
                            text="Learn more →"
                        />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
            alt=""
          />
        </div>
      </div>
    )
}