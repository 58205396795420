const people = [
    {
      name: 'Cobus Steyn',
      role: 'Co-Founder / CEO',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/PHOTO-2024-10-26-15-45-39.jpg?alt=media&token=c2ab1872-a4c8-418a-aa0e-2dc1f21bbb17',
      bio: "With over two decades of experience in international education, Cobus has held diverse leadership and consultancy roles. As a renowned accreditation expert and innovative education leader, he is dedicated to modernising education to meet the needs of today's world. An avid technophile, Cobus is committed to integrating technology and AI into education, empowering young people to embrace the exciting possibilities of AI while learning to engage with it responsibly.",
      linkedinUrl: 'https://linkedin.com/in/cobus-steyn-education/',
    },
    {
      name: 'Kaisa Sorjonen',
      role: 'Co-Founder / CFO',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/279c85c2-460c-4753-b7e5-540254ffc2dd.jpg?alt=media&token=06ffc891-9397-490d-b251-900034346fa9',
      bio: "With over 20 years in corporate finance and a decade in international school leadership, I bring expertise in start-ups, takeovers, and governance to support iHubs' growth. Recently completing an advanced HR degree, I’m excited to join a global community dedicated to building an innovative, supportive learning environment.",
      linkedinUrl: 'https://www.linkedin.com/in/kaisa-sorjonen-1483b12/',
    },
    {
      name: 'Felix Tikkanen',
      role: 'Co-Founder / CMO',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Untitled%20design%20(6).png?alt=media&token=2a8cd162-9f4a-4d07-954b-4039de57272f',
      bio: "I run and own a Digital Marketing Agency managing well over 6 figures a year in advertising budget. I have an avid interest in emerging technologies, which led to me help develop AI detection algorithms for Alzheimer's, Blockchain medical record infrastructure, wearable tech device which helps getting rid of opioid dependencies, ...",
      linkedinUrl: 'https://linkedin.com/in/felixtikkanen',
    },
   
  ]
  
  export default function Team() {
    return (
      <div className="bg-white py-24 md:py-32">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
          <div className="max-w-2xl xl:col-span-2">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the Founders</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We’re a dynamic group of experts who are all passionate about the intersection between our expertise and education.
            </p>
          </div>
          <ul role="list" className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
            {people.map((person) => (
              <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
                <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src={person.imageUrl} alt="" />
                <div className="max-w-xl flex-auto">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-base leading-7 text-gray-600">{person.role}</p>
                  <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                  <ul role="list" className="mt-6 flex gap-x-6">
                    
                    <li>
                      <a target="_blank"  href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  