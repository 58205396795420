export default function Privacy() {
    return (
        <section className="prose mt-24 mx-auto">
        <h1>PRIVACY POLICY</h1>
        <p>I-HUBS SOUTH AFRICA considers the privacy of its users to be a serious issue. Please read the following to learn more about our PRIVACY POLICY.</p>

        <h2>WHAT THIS PRIVACY POLICY COVERS</h2>
        <p>This policy covers how I-HUBS SOUTH AFRICA treats personal information that I-HUBS SOUTH AFRICA collects and receives, including information to your past use of our website. Personal information, is information about you that is personally identifiable like your name, address, email address or phone number, and that is not otherwise publicly available.</p>

        <p>This policy does not apply to the practices of companies that we do not own or control, or to people that we do not employ or manage.</p>

       <h2>INFORMATION COLLECTION AND USE</h2>
        <h3>GENERAL</h3>
        <p>
        I-HUBS SOUTH AFRICA collects personal information whenever a user chooses to contact us via a form on the website. This includes the enquiry form, admission form and book a call form.

        I-HUBS SOUTH AFRICA automatically receives and records information on our server logs from your browser, including your IP address, and the page you request.

        We use information for the following general purposes: to fulfil your requests and for technical web site administration purposes.
        </p>
        <h3>INFORMATION SHARING AND DISCLOSURE</h3>
        <p>

        We do not rent, sell or share personal information about you with other people or non-affiliated companies except to provide products or services you've requested, when we have your permission.

        We will use reasonable efforts to ensure that your personal data is not disclosed to regional/national institutions and authorities, unless required by law or other regulations.

        </p>
        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
        <p>

        We may update this policy. We will notify you about significant changes in the way we treat personal information by placing a prominent notice on our web site.

        </p>
        <h2>PRIVACY SUPPORT AND CONTACT INFORMATION</h2>
        <p>If you have any questions or suggestions, please contact us using the enquiry form on the bottom right hand side of the page.</p>


        </section>
    )
}