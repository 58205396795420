import Pricing from "../location/components/Pricing";
import Hero from "./components/hero";
import Offer from "./components/offer";

export default function SouthAfrica() {

    return (
        <section>
            <Hero />
            <Offer />
            <Pricing location={'hermanus'}  />
        </section>
    )
}