import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { PopupButton } from "react-calendly";

export default function Hero() {
    return (
    <div className="relative lg:h-screen">
        <div className="mx-auto max-w-7xl my-auto px-1 lg:px-0">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 flex items-center justify-center lg:pr-0 lg:my-auto lg:h-screen py-24">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Study at an <span className="text-b italic"> Elite International Cambridge school</span> starting at R48&nbsp;000&nbsp;/&nbsp;year.
                </h1>
                <ul className="mt-6 text-lg leading-8 text-gray-600 list-disc ml-10">
                    <li>Learn at your own pace</li>
                    <li>Accredited Curriculum</li>
                    <li>Personal study coaches and expert teachers</li>
                    <li>Fun and innovative extracurriculars</li>
                    <li>Less stressful than normal school</li>
                    <li>Future Technology and Mindset Modules</li>
                    <li>Focus on well-being</li>
                </ul>
                <div className="mt-10 flex items-center gap-x-6">
                  <Button
                    text="Get started"
                  />
                   <Link to="#" className="text-sm font-semibold leading-6 text-gray-900">
                    <PopupButton
                            url="https://calendly.com/c_steyn/30min"
                            rootElement={document.getElementById("root")}
                            text="Learn more →"
                        />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
            alt=""
          />
        </div>
      </div>
    )
}