import Button from "../../../components/Button";

export default function Exams() {
    return (
        <section className="flex flex-col md:flex-row gap-10 justify-center py-20">
            <img className="rounded-xl h-[500px] md:h-auto md:w-2/5 object-cover" src="https://firebasestorage.googleapis.com/v0/b/inventum-98847.appspot.com/o/website%2Fcambridgeexams.webp?alt=media&token=3cd7d742-c722-418d-aa8c-60f585e7f0b2" alt=""></img>
            <div className="prose prose-h3:text-2xl md:prose-h3:text-4xl prose-h3:font-bold text-start md:w-1/2 my-auto">
                <h2>Exams</h2>
                <div className="prose">
                    <p>When it comes to examinations, we offer comprehensive support across all our exam centres. iHubs facilitates IGCSE and A-level examinations from both Pearson and Cambridge. Additionally, iHubs supports online exams through our portal, giving students the option to take their exams online rather than in traditional settings.</p>
                    <p>Students enrolled with Inventum International Online School and iHubs also have the opportunity to participate in internal assessments. These assessments are designed to produce official school reports, ensuring academic integrity and quality assurance.</p>
                    <Button text="Apply" />
                </div>
            </div>
        </section>
        
    )
}