import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupButton } from "react-calendly";

export default function Contact() {
    return (
      <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-64}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div className="mx-auto">
          <div className="max-w-xl lg:max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900">Contact us</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              If you're interested in enrolling your child into iHubs, send us a message by filling in the form below and we'll contact you!
            </p>
            <div className="p-4 max-w-2xl ">
                  {/* WhatsApp Contact Button */}
                  <a
                      href="https://wa.me/+27601092525" // Replace with your WhatsApp Business number
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center bg-green-500 text-white p-3 rounded-lg mb-4 hover:bg-green-600"
                      >
                      <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                      Contact us on WhatsApp
                  </a>

                  {/* Book a Call Button */}
                  <PopupButton
                      url="https://calendly.com/c_steyn/30min"
                      rootElement={document.getElementById("root")}
                      text="Book a Call"
                      className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700"
                      />
            </div>
          </div>
          <div className="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row max-w-7xl mx-auto">
            <div className="w-full h-[65rem] ">
                <iframe
                src="https://api.leadconnectorhq.com/widget/form/TMDgVfWMUoViRUz1KJri"
                style={{width:"100%", height:"100%", border: "none", borderRadius: "3px"}}
                id="inline-TMDgVfWMUoViRUz1KJri" 
                data-layout="{'id':'INLINE'}"
                data-trigger-type="alwaysShow"
                data-trigger-value=""
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="neverDeactivate"
                data-deactivation-value=""
                data-form-name="Enquiry"
                data-height="783"
                data-layout-iframe-id="inline-TMDgVfWMUoViRUz1KJri"
                data-form-id="TMDgVfWMUoViRUz1KJri"
                title="Enquiry"
                    >
              </iframe>
              <script src="https://link.msgsndr.com/js/form_embed.js"></script>
            </div>
            <div className="lg:mt-6 lg:w-2/5 lg:flex-none">
              <img className="h-20 w-auto" src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Home%2FColor%20logo%20-%20no%20background.png?alt=media&token=1b998f84-2e5c-4995-ada7-8f5eb9c5fa5a" alt="" />
              <figure className="mt-10">
                <blockquote className="text-lg font-semibold leading-8 text-gray-900">
                  <p>
                  “iHubs is the future of education. The modern world facilitates and promotes online learning which ensures inquisitive minds can explore and develop their interests, while maintaining a strong social connection with others at our iHubs. Our model ensures that young people develop and hone their social skills, while engaging in fun and exciting activities with their peers. They pursue their academics online, at their own pace and on their own terms, thus taking ownership of their education. iHubs provide the vehicle for social learning and developing interests beyond academics, developing well-rounded young people ready for their future. iHubs is not a cottage school, but a way of life.”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex gap-x-6">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/PHOTO-2024-10-26-15-45-39.jpg?alt=media&token=c2ab1872-a4c8-418a-aa0e-2dc1f21bbb17"
                    alt=""
                    className="h-12 w-12 flex-none object-cover object-top rounded-full bg-gray-50"
                  />
                  <div>
                    <div className="text-base font-semibold text-gray-900">Cobus Steyn</div>
                    <div className="text-sm leading-6 text-gray-600">Founder of iHubs</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    )
  } 
  