import React from 'react';
import { Link } from 'react-router-dom';

const locations = {
  "hermanus": {
    title: "Hermanus",
    content: "Located along the beautiful coast of South Africa, our Hermanus hub offers a serene environment conducive to learning and personal growth. Students can enjoy the natural beauty of the area while participating in various social and educational activities designed to enhance their overall experience.",
    activities: [
      { time: '10h00 - 11h30', Monday: 'Virtual Reality', Tuesday: 'Free Art', Wednesday: 'Robotics and Coding', Thursday: 'Free Art', Friday: 'Digital Creativity' },
      { time: '12h00 - 13h30', Monday: 'Free Art', Tuesday: 'Learning Support', Wednesday: 'Mid-week Hike/Trip', Thursday: 'Social Builder', Friday: 'Learning Support' },
      { time: '14h00 - 15h30', Monday: 'Digital Creativity', Tuesday: 'Virtual Reality', Wednesday: '', Thursday: 'Virtual Reality', Friday: 'Career Explorer' },
      { time: '16h00 - 17h30', Monday: 'Social Builder', Tuesday: 'Adulting 101', Wednesday: '', Thursday: 'Robotics and Coding', Friday: '' },
      { time: '18h00 - 19h30', Monday: 'Adulting 101', Tuesday: 'Digital Creativity', Wednesday: '', Thursday: 'Career Explorer', Friday: '' },
    ],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2F01g.webp?alt=media&token=c6e4bdec-d8ae-4557-a9cd-735a7b559fbb",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2F4389231-birkenhead-house-hotel-hermanus-south-africa.webp?alt=media&token=e6a54642-c662-4bc0-8d81-7914cc3cae1c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2FHermanus_Old_Harbour.webp?alt=media&token=3076c212-c54d-4a83-ae43-1478c919829e",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2FIMG_3300-1170x550.webp?alt=media&token=210e4eb9-150d-4a85-9387-f2bf5076a740",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2FUS_Hermanus_ZA_Header.webp?alt=media&token=b13c6553-7940-4473-bca3-257be1c8dbb5"
    ]
  },
  "mossel-baai": {
    title: "Mossel Baai (Coming Soon) ",
    content: "Our Mossel Baai hub is situated in the heart of the Garden Route, offering students a unique blend of educational excellence and breathtaking scenery. This location provides ample opportunities for outdoor activities and community engagement, fostering a well-rounded educational experience.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FMosselBaai%2FDepositphotos_318568140_l-2015-1920x1220.jpg.webp?alt=media&token=bd769e9e-6541-46c0-9403-87ffb3f0fdc7",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FMosselBaai%2FMosselBay.webp?alt=media&token=cc7919f6-0633-4547-b12a-61dc83aea957",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FMosselBaai%2Fcruise-to-mossel-bay-south-africa.webp?alt=media&token=c4000fbd-f38d-4781-91a7-fad41d244e35",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FMosselBaai%2Fheader-mossel-bay-zuid-afrika-1920x500.webp?alt=media&token=dee93f77-f503-4fb3-81c2-6ea07c236d87",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FMosselBaai%2Fmosselbay-tourism-sky.webp?alt=media&token=7ae2442e-5b3e-4ce7-85b5-42fe059a3027"
    ]
  },
  "lisbon": {
    title: "Lisbon (Coming Soon) ",
    content: "The Lisbon hub, located in Portugal's vibrant capital, immerses students in a rich cultural environment. With access to historical sites, modern amenities, and a diverse community, students can enhance their social and academic skills in one of Europe's most dynamic cities.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FLisbon%2FAdobeStock_382180612.webp?alt=media&token=e4537902-6375-470a-86a7-fb9f95cc4a26",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FLisbon%2FLisbon2.webp?alt=media&token=0b2df1b6-93d5-4c6c-a4b2-3380b266a3ad",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FLisbon%2FPortugal-news-image-1.webp?alt=media&token=44ed2b62-403c-4a83-9544-9aa1bad32ca8",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FLisbon%2Fpraca-do-comercio-lisbon-GettyImages-648812458.webp?alt=media&token=a4eef57e-dcb5-4385-9755-5ed885b58045",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FLisbon%2FshutterstockRF_755689225.webp?alt=media&token=9c2f948b-1af1-41b0-a0be-f6bfcb862e7d"
    ]
  },
  "johannesburg": {
    title: "Johannesburg (Coming Soon) ",
    content: "Our Johannesburg hub, located in South Africa's largest city, combines urban energy with educational innovation. Students here have access to a range of cultural, social, and academic resources, promoting a comprehensive and engaging learning experience.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FJoBurg%2F104530129.webp?alt=media&token=91214caa-82ca-40cc-8693-b689e9fd078e",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FJoBurg%2F170918153733-04-johannesburg-restricted.webp?alt=media&token=3b222f82-8d01-4844-bb22-290cb4110ffa",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FJoBurg%2FJohannesburg-Horizontal.webp?alt=media&token=4c085000-794e-487c-ae41-8782bc5acfb6",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FJoBurg%2FJohannesburg-Skyline.webp?alt=media&token=30eaba30-040c-4a8e-9d4f-1623c8b1dc6c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FJoBurg%2Fcity-night.webp?alt=media&token=4ac65f78-2f5b-4c48-bb3a-5a4909418cad"
    ]
  },
  "pretoria": {
    title: "Pretoria (Coming Soon) ",
    content: "Located in South Africa's administrative capital, our Pretoria hub provides students with access to numerous educational and cultural institutions. The city's blend of history and modernity creates an enriching environment for learning and personal growth.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FPretoria%2FPretoria-SAf.webp?alt=media&token=04771d1b-21d7-431d-a7c1-b68703a1c84a",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FPretoria%2FUniegebou.webp?alt=media&token=497d7175-e14a-408e-8802-dc24cb0316a2",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FPretoria%2Fmaxresdefddault-1024x576.webp?alt=media&token=72b9db1a-7df7-4385-b804-8e9f0b8ee147",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FPretoria%2Fpretoria-city-gauteng-590x390.webp?alt=media&token=d690a515-fb17-438f-8af9-8982f6953534",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FPretoria%2Fpretoria-jacaranda-road.webp?alt=media&token=0e132226-5442-4acd-b0e7-54ab044a95f9"
    ]
  },
  "windhoek": {
    title: "Windhoek (Coming Soon) ",
    content: "The Windhoek hub in Namibia's capital offers a dynamic setting for students to pursue their studies. With a focus on community engagement and cultural exploration, students can develop their academic and social skills in a supportive environment.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FWindhoek%2F360_F_178663498_fZfy49meHaqHuMYBOAgC7SIu1x2DqAdM.webp?alt=media&token=ea2db7e1-2daf-49f7-b851-a4db15abe8b8",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FWindhoek%2F5-things-to-do-in-windhoek-blog.webp?alt=media&token=029e88f1-3d2b-479c-bad5-c11dd431220a",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FWindhoek%2FWindhoek.webp?alt=media&token=0fe629ba-8de2-4bc4-980a-4f275be08e8a",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FWindhoek%2Fistockphoto-1137749821-612x612.webp?alt=media&token=62fabab4-c14f-4dda-9540-9e3d32a90648",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FWindhoek%2Fshutterstock_376391041-editorial-only-anton_ivanov-shutterstock.com_.webp?alt=media&token=1c8fb0c8-f095-40e7-b6f3-6c6996813674"
    ]
  },
  "albufeira": {
    title: "Albufeira (Coming Soon) ",
    content: "Located in the Algarve region of Portugal, the Albufeira hub provides a beautiful coastal setting for education. Students can enjoy the area's natural beauty while participating in various activities that promote learning and personal development.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAlbufeira%2F72.webp?alt=media&token=e86cf7ea-746a-463e-9d61-b8c7557ca56c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAlbufeira%2FAlgarve-Grande-Real-Santa-Eulalia-Resort-Hotel-Spa-Albufeira-001-2200x1200.webp?alt=media&token=75f925d0-537d-4280-8c7c-961d05aaa3c2",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAlbufeira%2FFAO_Albufeira_Overview__Marina_955186632_Getty_RGB-136-DPI-For-Web.webp?alt=media&token=29c2b1b6-6b89-4347-b2a0-159df35309b1",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAlbufeira%2FWhat-to-do-in-Albufeira.webp?alt=media&token=2d353dac-73b6-479a-bfc5-33d376e035b1",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAlbufeira%2Fhaven-van-albufeira-bezoeken-algarve_27412_xl.webp?alt=media&token=eb91fc9c-cba5-4cc2-b248-cec1a1041ad0"
    ]
  },
  "kuala-lumpur": {
    title: "Kuala Lumpur (Coming Soon) ",
    content: "Our Kuala Lumpur hub, situated in Malaysia's bustling capital, offers a multicultural environment that enhances educational and social experiences. Students can benefit from the city's modern infrastructure and vibrant community while pursuing their academic goals.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FKuala%20Lumpur%2F1F159175-0757-4F9E-A9A8EC6DBD5BCE68.webp?alt=media&token=aa933682-8967-46fb-88d1-3e60d50a933d",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FKuala%20Lumpur%2FKuala-Lumpur-World-Class-Business-Hub-1100x619-1.webp?alt=media&token=4b00ee75-80ce-471b-b88a-5774a45d59a6",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FKuala%20Lumpur%2FThean-Hou-Temple-Kuala-Lumpur-Malaysia.webp?alt=media&token=3fa147f4-69cf-4c9e-8d14-a188d325a642",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FKuala%20Lumpur%2Fparkroyal-collection-hotel-kuala-lumpur-fdat-architects_1.webp?alt=media&token=8a1a2ff4-ef72-49f4-82e7-a936953b0b27",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FKuala%20Lumpur%2Fwebiste_KL_cover_10.webp?alt=media&token=d18f28cb-9703-435f-a6ed-d6f617c01def"
    ]
  },
  "abuja": {
    title: "Abuja (Coming Soon) ",
    content: "The Abuja hub in Nigeria's capital provides students with a supportive and engaging learning environment. With access to diverse cultural and educational resources, students can develop their skills and knowledge in a city known for its dynamic growth and opportunities.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAbuja%2F007-6-developing-locations-in-abuja.webp?alt=media&token=85fd1a6e-a880-4877-829c-00a4cb14770e",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAbuja%2F9dc3087797834cbdb39040a7d1e564c2_18.webp?alt=media&token=570e1c46-901f-418f-9162-2648a62bcf23",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAbuja%2FAbuja%2C_Federal_Capital_Territory_3.webp?alt=media&token=4ea40935-d760-4896-858b-1fcc627905fa",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAbuja%2Fabuja-1589282102432.webp?alt=media&token=328a523a-5ca9-47cc-9d8f-e9e3d0245ae4",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAbuja%2Funnamed.webp?alt=media&token=8be8c96c-614e-4dfd-a914-7356448f5a52"
    ]
  }
};


const LocationCard = ({ title, content, images, link }) => (
  <Link className="max-w-sm rounded overflow-hidden shadow-lg m-4" to={`${link}`}>
    <div className='w-full h-[250px]'>
      <img className="object-cover w-full h-full" src={images[0]} alt={title} />
    </div>
    <div className="px-6 py-4">
      <div className="font-bold text-xl mb-2">{title}</div>
      <p className="text-gray-700 text-base">{content}</p>
    </div>
    <div className="px-6 gap-4 pt-4 pb-2 flex justify-between overflow-x-auto">
      {images.map((img, index) => (
        <img key={index} className="w-24 h-24 rounded-xl mr-2" src={img} alt={`Thumbnail ${index + 1}`} />
      ))}
    </div>
  </Link>
);

const LocationsList = () => (
  <div className="flex flex-wrap justify-center"
  >
    {Object.entries(locations).map(([name, location]) => (
      <LocationCard
        key={name}
        title={location.title}
        content={location.content}
        images={location.images}
        link={`/${name}`}
      />
    ))}
  </div>
);

const Locations = () => (
  <section className="container mx-auto px-4">
    <h2 className="text-3xl font-bold text-center mt-8">Our Locations</h2>
    <p className='prose text-center mx-auto'>To see the packages we offer, go to your location.</p>
    <LocationsList />
  </section>
);

export default Locations;
