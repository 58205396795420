import { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

export default function SouthAfricaPopup() {
  const [isMinimized, setIsMinimized] = useState(true);

  return (
    <div className={`fixed bottom-0 left-0 m-4 p-6  shadow-lg border border-gray-200 rounded-lg transition-all duration-300 ease-in-out ${isMinimized ? 'h-12 w-12 bg-b hover:bg-b/80' : 'w-[1/3] bg-b'} z-50`}>
      <div className="flex items-center justify-between relative">
        <h3 className={`${isMinimized ? 'hidden' : 'text-lg font-semibold text-white'}`}>
            Are you in South Africa?
        </h3>
        <button
          className={`ml-auto  font-bold py-1 px-2 rounded ${isMinimized ? ' absolute -left-3 text-white' : 'text-white'}`}
          onClick={() => setIsMinimized(!isMinimized)}
        >
          {isMinimized ? '+' : '-'}
        </button>
      </div>
      {isMinimized && (
        <button onClick={() => setIsMinimized(!isMinimized)}>
            <p className='absolute -top-1/2 -right-[250%] whitespace-nowrap bg-b rounded-xl px-2 py-1 text-white font-bold border-white border'>In South Africa?</p>
        </button>
        )}
      {!isMinimized && (
        <div className="mt-4">
          <p className="text-gray-50 max-w-xl text-pretty">
            If you're located in South Africa, please click the button below to be redirected to the South Africa page. You will find more information about pricing and the available packages to choose from there.
          </p>
          <Link
            to="/south-africa"
            className="mt-4 inline-block bg-white hover:bg-opacity-80 text-b font-bold py-2 px-4 rounded"
          >
            Go to the South Africa Page
          </Link>
        </div>
      )}
    </div>
  );
}
