import { InlineWidget } from "react-calendly";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


export default function CallInline() {
    return (
        <section className="">
            <h2 className="text-center">Need help? Contact us and we'll help you!</h2>
            <div className="space-x-4 mb-4 max-w-xs mx-auto">
                        <a
                            href="https://wa.me/+27601092525" // replace with your WhatsApp Business number
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center bg-green-500 text-white text-lg font-medium p-3 rounded-md mb-4 w-full hover:bg-green-600"
                        >
                            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                            Contact on WhatsApp
                        </a>
            </div>
            <InlineWidget 
                url="https://calendly.com/c_steyn/30min"
                utm={{salesforce_uuid: localStorage.getItem("gclid") || null}}
                />
        </section>
    )
}